import { EventSuggestionFragment } from '@graphql/generated';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { ActiveSuggestionIdentifiers } from '../SuggestionCard';

const SUGGESTION_IDENTIFIERS: ReadonlyArray<ActiveSuggestionIdentifiers> = [
  'reg-get-started',
  'reg-shop',
  'reg-connect-registries',
  'reg-browse-cash-funds',
  'reg-discount',
  'onboarding-designs',
  'new-update-designs-2020-01-14',
  'onboarding-add-page-photos',
  'writersblock'
];
const SUGGESTION_IDENTIFIERS_SET = new Set(SUGGESTION_IDENTIFIERS);

/**
 * Filter the user's event suggestions based on state, if any.
 */
export const getEventSuggestionIdentifiersToShow = (suggestions: Maybe<ReadonlyArray<EventSuggestionFragment>>, showAll?: boolean): Set<ActiveSuggestionIdentifiers> => {
  const identifiersToShowSet = new Set<ActiveSuggestionIdentifiers>();
  if (suggestions) {
    const notshow: string[] = [];
    suggestions.forEach(({ identifier, stateJSON }) => {
      let shouldConsider: boolean = false;

      // Only consider suggestion if identifier is supported
      if (SUGGESTION_IDENTIFIERS_SET.has(identifier as ActiveSuggestionIdentifiers)) {
        // If no state has been saved, the suggestion is considered "fresh"
        if (!stateJSON || showAll) {
          shouldConsider = true;
        }
        // Show suggestion based on persisted state
        else {
          // If user has interacted (positive or negative) with the card, do not show.
          if (stateJSON.usedAt || stateJSON.declinedAt) {
            shouldConsider = false;
          }
          // If they haven't decided just yet, remind the user of the card after 7 days
          else if (stateJSON.snoozedAt) {
            try {
              const snoozedAtDate = parseISO(stateJSON.snoozedAt);
              const daysSinceSnoozeAt = differenceInCalendarDays(Date.now(), snoozedAtDate);
              shouldConsider = daysSinceSnoozeAt >= 7;
            } catch (e) {}
          }
        }
      }

      if (shouldConsider) {
        identifiersToShowSet.add(identifier as ActiveSuggestionIdentifiers);
      } else {
        notshow.push(identifier);
      }
    });
  }

  return identifiersToShowSet;
};

export const getMissingSuggestionIdentifiers = (
  suggestions: Maybe<ReadonlyArray<EventSuggestionFragment>>,
  availableIdentifiers: string[] = SUGGESTION_IDENTIFIERS as string[]
): ReadonlyArray<string> => {
  let missingIdentifiers: string[] = [];
  if (suggestions) {
    const currentIdentifiersSet = new Set(suggestions.map(x => x.identifier));
    missingIdentifiers = availableIdentifiers.filter(id => !currentIdentifiersSet.has(id));
  }
  return missingIdentifiers;
};
