import { styled } from '../../common';

export const TriggerText = styled.span`
  border-bottom-style: dashed;
  border-bottom-color: currentcolor;
  border-bottom-width: 1px;
`;

export const TooltipContent = styled.div`
  max-width: 350px;
  padding: 16px;
  font-size: 13px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
`;
