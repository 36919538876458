import { Box, styled, TextV2 } from '@withjoy/joykit';

import { ReactComponent as ZeroStateSvg } from './assets/zero-state.svg';

export const StyledZeroStateIcon = styled(ZeroStateSvg)``;
export const StyledViewPastText = styled(TextV2)``;
export const StyledPullCard = styled(Box)`
  position: relative;
  &::after {
    content: ' ';
    display: block;
    height: 24px;
    top: -24px;
    position: absolute;
    left: 12px;
    right: 12px;
    background: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;
