import React from 'react';
import { IconWrapper } from './IconWrapper';
import { PreviewToggleIconProps } from './PreviewToggleIcons';
import { Stationery } from '@withjoy/joykit/icons';

export const IconStationery: React.FC<PreviewToggleIconProps> = props => {
  return (
    <IconWrapper {...props} a11yLabel="matching stationery">
      <Stationery />
    </IconWrapper>
  );
};
