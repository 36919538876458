/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'New Window',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M10.378 3.408H2.994a.9.9 0 0 0-.9.9v4.757a.9.9 0 0 0 .9.9h.627v-3.03a2 2 0 0 1 2-2h7.384a2 2 0 0 1 2 2v4.757a2 2 0 0 1-2 2H5.621a2 2 0 0 1-2-2v-.627h-.627a2 2 0 0 1-2-2V4.308a2 2 0 0 1 2-2h7.384a2 2 0 0 1 2 2v.627h-1.1v-.627a.9.9 0 0 0-.9-.9zM5.621 6.035h7.384a.9.9 0 0 1 .9.9v4.757a.9.9 0 0 1-.9.9H5.621a.9.9 0 0 1-.9-.9V6.935a.9.9 0 0 1 .9-.9z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
