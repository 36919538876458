import React from 'react';
import { useImmer } from 'use-immer';
import { animated, SpringBaseProps, useSpring } from 'react-spring';

import { ComponentWithAs, forwardRef } from '@shared/utils/forwardRef';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { BoxProps, Box } from '@withjoy/joykit';
import { TextV2, TextV2Props, ButtonV2, ButtonV2Props } from '@withjoy/joykit';
import { CardSize, CardAlignment, CardProps } from './Card.types';
import { CardContextProvider, useCardContext } from './Card.context';
import { getCardStyles, cardContentStyles, cardTitleStyles, cardBodyStyles, cardActionStyles, getCardBgImageStyles } from './Card.styles';
import { ChevronRight, Close } from '@withjoy/joykit/icons';

const CardCloseButton = forwardRef<'button', ButtonV2Props>(({ children, ...restProps }, ref) => {
  const { isCardMousedOver } = useCardContext();

  const styles = useSpring({
    opacity: isCardMousedOver ? 0.6 : 0
  });
  return (
    <ButtonV2
      ref={ref}
      as={animated.button}
      style={styles}
      variant="ghost"
      shape="square"
      size="md"
      position="absolute"
      top="8px"
      right="8px"
      zIndex="docked"
      color="mono14"
      tabIndex={isCardMousedOver ? 0 : -1}
      overrides={{
        Root: {
          props: {
            _hover: {
              backgroundColor: 'transparent'
            }
          }
        }
      }}
      {...restProps}
    >
      <Close />
    </ButtonV2>
  );
});

const CardContent = forwardRef<'div', BoxProps>(({ children, ...restProps }, ref) => {
  return (
    <Box ref={ref} {...cardContentStyles} {...restProps}>
      {children}
    </Box>
  );
});

////////////////

const CardTitle = forwardRef<'h2', TextV2Props>(({ children, ...restProps }, ref) => {
  return (
    <TextV2 tagName="h2" ref={ref} {...cardTitleStyles} {...restProps}>
      {children}
    </TextV2>
  );
});

////////////////

const CardBody = forwardRef<'div', BoxProps>(({ children, ...restProps }, ref) => {
  return (
    <Box ref={ref} {...cardBodyStyles} {...restProps}>
      {children}
    </Box>
  );
});

////////////////

const CardActions = forwardRef<'div', BoxProps>(({ children, ...restProps }, ref) => {
  return (
    <Box ref={ref} cursor="pointer" {...cardActionStyles} {...restProps}>
      {children}
      <ChevronRight size={'sm'} marginLeft={1} />
    </Box>
  );
});

////////////////

const CardBgImage = forwardRef<'div', { imageUrl: string } & BoxProps>(({ children, imageUrl, ...restProps }, ref) => {
  const { cardAlignment, isCardMousedOver } = useCardContext();
  const styles = useSpring<{ transform: string; config: SpringBaseProps['config'] }>({
    transform: isCardMousedOver ? 'scale(1.05)' : 'scale(1)',
    config: {
      tension: 350,
      friction: 26,
      delay: 200
    }
  });

  return (
    <Box as={animated.div} ref={ref} style={styles} {...getCardBgImageStyles(imageUrl, cardAlignment)} {...restProps}>
      {children}
    </Box>
  );
});

////////////////

const defaultCardProps = {
  size: 'md' as CardSize,
  alignment: 'left' as CardAlignment
} as const;

type CardComponent = ComponentWithAs<'div', CardProps> & {
  CloseButton: typeof CardCloseButton;
  Content: typeof CardContent;
  Title: typeof CardTitle;
  Body: typeof CardBody;
  Actions: typeof CardActions;
  BgImage: typeof CardBgImage;
};

const Card: CardComponent = forwardRef<'div', CardProps>((props, ref) => {
  const { children, disableElevation, size, alignment, onMouseEnter, onMouseLeave, style, transitionConfig, ...restProps } = props as CardProps & typeof defaultCardProps;

  const [{ isCardMousedOver }, setState] = useImmer<{ isCardMousedOver: boolean }>({ isCardMousedOver: false });

  const handleOnMouseEnter = useEventCallback<React.MouseEventHandler<HTMLDivElement>>(e => {
    setState(draft => {
      draft.isCardMousedOver = true;
    });

    onMouseEnter?.(e);
  });

  const handleOnMouseLeave = useEventCallback<React.MouseEventHandler<HTMLDivElement>>(e => {
    setState(draft => {
      draft.isCardMousedOver = false;
    });

    onMouseLeave?.(e);
  });

  const { scale, ...transitionStyle } = useSpring({
    boxShadow: disableElevation
      ? '0px 0px 0px rgba(255, 255, 255, 0), 0px 0px 0px rgba(255, 255, 255, 0)'
      : isCardMousedOver
      ? '0px 30px 60px rgba(21, 89, 126, 0.25), 0px 18px 36px rgba(0, 0, 0, 0.3)'
      : '0px 7px 27px rgba(44, 41, 37, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.07)',
    scale: isCardMousedOver && !disableElevation ? 1.05 : 1,
    ...transitionConfig
  });

  return (
    <CardContextProvider value={{ cardAlignment: alignment, cardSize: size, isCardMousedOver }}>
      <Box
        as={animated.div}
        color="black"
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        style={{ willChange: 'transform', transform: scale?.interpolate(scale => `scale(${scale})`), ...transitionStyle, ...style }}
        ref={ref}
        {...getCardStyles(alignment, size)}
        {...restProps}
      >
        {children}
      </Box>
    </CardContextProvider>
  );
}) as CardComponent;

Card.defaultProps = defaultCardProps;
Card.displayName = 'Card';
Card.BgImage = CardBgImage;
Card.Content = CardContent;
Card.CloseButton = CardCloseButton;
Card.Title = CardTitle;
Card.Body = CardBody;
Card.Actions = CardActions;

export { Card, CardBgImage, CardContent, CardTitle, CardBody, CardActions };
