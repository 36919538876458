import { StyleSystemProps } from '@withjoy/joykit';

const textStyles: StyleSystemProps = {
  typographyVariant: 'body1',
  lineHeight: '48px',
  height: '48px',
  textAlign: 'center',
  display: 'inline-block'
};

export default {
  textStyles
};
