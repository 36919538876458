import React from 'react';
import { generateComponentDisplayName } from '../../utils';
import { SharedPopoverProps } from '../PopoverV1/sharedPopoverProps';
import { Popover } from '../PopoverV1';
import { TriggerText, TooltipContent } from './styles';
import { PopoverInteractionVariant } from '../PopoverV1/types';

const TOOLTIP_CLASS = `jk-tooltip-arrow`;

export interface TooltipV1Props extends SharedPopoverProps {
  /**
   * Content to be displayed.
   *
   * When the content is a `string`, it will be wrapped in a span that applies styling
   * to indicate that it is a tooltip.
   */
  content: string | JSX.Element;

  interactionVariant?: typeof PopoverInteractionVariant.HOVER | typeof PopoverInteractionVariant.HOVER_TARGET;

  /** @default false */
  usePortal?: boolean;
}

export const TooltipV1: React.FC<TooltipV1Props> = React.memo(props => {
  const { children, content, interactionVariant, usePortal, ...restProps } = props;

  const [target] = React.Children.toArray(children);

  let targetMarkup = target;
  if (target && typeof target === 'string') {
    targetMarkup = <TriggerText>{target}</TriggerText>;
  }

  const childrenMarkup = <TooltipContent>{content}</TooltipContent>;

  return (
    <Popover {...restProps} usePortal={usePortal} interactionVariant={interactionVariant} showArrow={true} canCloseOnOutsideClick={false} popoverArrowClassName={TOOLTIP_CLASS}>
      {targetMarkup}
      {childrenMarkup}
    </Popover>
  );
});

TooltipV1.defaultProps = {
  interactionVariant: PopoverInteractionVariant.HOVER_TARGET,
  usePortal: false
};

TooltipV1.displayName = generateComponentDisplayName('Tooltip');
