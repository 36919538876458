import { styled, Box } from '@withjoy/joykit';

export const StyledContentTransitionBox = styled(Box)`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  will-change: transform;
`;
