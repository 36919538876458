import { Flex, IconV1, Stack, styled } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

export const PreviewToggleWrapper = styled.div`
  transition: ${animationTransition('max-width')};
  display: flex;
  & > *:not(:first-child) {
    margin-left: 2.5rem;
  }
`;
export const PopoverContent = styled(Stack)`
  padding: 0.5rem 1rem;
`;
export const PopoverActionIcon = styled(IconV1)`
  align-items: center;
`;
export const PopoverActionWrapper = styled(Flex)<{ isOpen: boolean }>`
  align-items: center;
  ${PopoverActionIcon} {
    transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
  }
  & > :first-child {
    margin-right: 6px;
  }
`;
