/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Desktop',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3.2H21C21.4418 3.2 21.8 3.55817 21.8 4V15.5C21.8 15.9418 21.4418 16.3 21 16.3H3C2.55817 16.3 2.2 15.9418 2.2 15.5V4C2.2 3.55817 2.55817 3.2 3 3.2ZM1 4C1 2.89543 1.89543 2 3 2H21C22.1046 2 23 2.89543 23 4V15.5C23 16.6046 22.1046 17.5 21 17.5H15.1194L15.9656 20.8H16.4C16.7314 20.8 17 21.0686 17 21.4C17 21.7314 16.7314 22 16.4 22H15.5127C15.5039 22.0002 15.4952 22.0002 15.4864 22H8.51355C8.50972 22.0001 8.50589 22.0001 8.50206 22.0001C8.49715 22.0002 8.49222 22.0001 8.48729 22H7.6C7.26863 22 7 21.7314 7 21.4C7 21.0686 7.26863 20.8 7.6 20.8H8.03443L8.88059 17.5H3C1.89543 17.5 1 16.6046 1 15.5V4ZM10.1001 17.5C10.1001 17.5492 10.094 17.5992 10.0812 17.649L9.27325 20.8H14.7267L13.9188 17.649C13.906 17.5992 13.8999 17.5492 13.8998 17.5H10.1001Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.66667 4.2H28.3333C29.1434 4.2 29.8 4.85665 29.8 5.66667V20.8488C29.8 21.6589 29.1434 22.3155 28.3333 22.3155H3.66667C2.85665 22.3155 2.2 21.6589 2.2 20.8488V5.66667C2.2 4.85665 2.85665 4.2 3.66667 4.2ZM1 5.66667C1 4.19391 2.19391 3 3.66667 3H28.3333C29.8061 3 31 4.19391 31 5.66667V20.8488C31 22.3216 29.8061 23.5155 28.3333 23.5155H20.032L21.2282 27.8001H22C22.3314 27.8001 22.6 28.0688 22.6 28.4001C22.6 28.7315 22.3314 29.0001 22 29.0001H20.7859C20.7769 29.0003 20.7679 29.0003 20.7589 29.0001H11.2411C11.2321 29.0003 11.2231 29.0003 11.2141 29.0001H10C9.66863 29.0001 9.4 28.7315 9.4 28.4001C9.4 28.0688 9.66863 27.8001 10 27.8001H10.7718L11.968 23.5155H3.66667C2.19391 23.5155 1 22.3216 1 20.8488V5.66667ZM13.1911 23.5155C13.191 23.5688 13.1838 23.623 13.1688 23.6768L12.0177 27.8001H19.9823L18.8312 23.6768C18.8161 23.623 18.809 23.5688 18.8089 23.5155H13.1911Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
