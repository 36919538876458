import { OnCardInteract } from '@apps/suggestions/components/SuggestionCard';
import chroma from 'chroma-js';

export const deriveNextCardStateFromInteraction = (args: Parameters<OnCardInteract>[0]) => {
  const { interaction, stateJSON } = args;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nextStateJson = { ...stateJSON } as any;
  const nowTimestamp = new Date().toISOString();

  const unsetOrIgnoreStateKey = (stateKey: 'usedAt' | 'snoozedAt' | 'declinedAt') => {
    if (nextStateJson[stateKey] !== undefined) {
      nextStateJson[stateKey] = null;
    }
  };

  let interactionStateKey: 'usedAt' | 'snoozedAt' | 'declinedAt';
  let shouldUnsetUsedAt: boolean = false;
  let shouldUnsetSnoozedAt: boolean = false;
  let shouldUnsetDeclinedAt: boolean = false;

  if (interaction === 'use') {
    interactionStateKey = 'usedAt';
    shouldUnsetSnoozedAt = true;
    shouldUnsetDeclinedAt = true;
  } else if (interaction === 'snooze') {
    interactionStateKey = 'snoozedAt';
    shouldUnsetUsedAt = true;
    shouldUnsetDeclinedAt = true;
  } else {
    interactionStateKey = 'declinedAt';
    shouldUnsetUsedAt = true;
    shouldUnsetSnoozedAt = true;
  }

  if (shouldUnsetUsedAt) {
    unsetOrIgnoreStateKey('usedAt');
  }
  if (shouldUnsetSnoozedAt) {
    unsetOrIgnoreStateKey('snoozedAt');
  }
  if (shouldUnsetDeclinedAt) {
    unsetOrIgnoreStateKey('declinedAt');
  }
  nextStateJson[interactionStateKey] = nowTimestamp;

  return nextStateJson;
};

///////////////

const TWO_HOURS = 60 * 60 * 1000 * 2;
export const shouldShowPreviewFirst = (creationDate: Maybe<number>) => {
  if (creationDate && Date.now() - creationDate < TWO_HOURS) {
    return true;
  }

  return false;
};

///////////////

export type ColorRGBA = [r: number, g: number, b: number, a: number];
export type Color = ColorRGBA | string;

const transformToChromaColor = (color: Color): chroma.Color => {
  const input = typeof color === 'string' ? color : `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
  return chroma(input);
};

/**
 * Contrast ratios can range from 1 to 21 (commonly written 1:1 to 21:1).
 *
 * https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 */
export const getContrastRatio = (foreground: Color, background: Color): number => {
  const lumA = transformToChromaColor(foreground).luminance();
  const lumB = transformToChromaColor(background).luminance();

  const lighter = Math.max(lumA, lumB);
  const darker = Math.min(lumA, lumB);

  return (lighter + 0.05) / (darker + 0.05);
};

/**
 * Enhanced standards
 */
const CONTRAST_THRESHOLD = 4.5;
const CONTRAST_THRESHOLD_MIN = 3.0;
const LIGHT_FOREGROUND = '#333333';
const DARK_FOREGROUND = '#ffffff';

export const getContrastText = (background: Color): string => {
  const contrastText = getContrastRatio(DARK_FOREGROUND, background) >= CONTRAST_THRESHOLD ? DARK_FOREGROUND : LIGHT_FOREGROUND;
  if (process.env.NODE_ENV !== 'production') {
    const contrast = getContrastRatio(contrastText, background);
    if (contrast < CONTRAST_THRESHOLD_MIN) {
      console.error(`Contrast ratio of ${contrast}:1 for ${contrastText} on ${background} falls below the absolute minimum contrast threshold of 3.0`);
    }
  }

  return contrastText;
};

export const fadeColor = (color: Color, alpha: number): string => {
  const chromaColor = transformToChromaColor(color);
  return chromaColor.alpha(alpha).css();
};

/**
 * Darken or brighten a color depending on its luminance.
 */
export const emphasizeColor = (color: Color, coefficient = 0.15): string => {
  const chromaColor = transformToChromaColor(color);
  const nextColor = chromaColor.luminance() > 0.5 ? chromaColor.darken(coefficient) : chromaColor.brighten(coefficient);
  return nextColor.css();
};

export const colorToCss = (color: Color): string => {
  return transformToChromaColor(color).css();
};
