import React from 'react';
import { styled } from '@withjoy/joykit';
import { animationTransitionExt } from '@shared/utils/animationTransition';
import { IconV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { PreviewToggleIconProps } from './PreviewToggleIcons';

export const TOGGLES_ANIMATION_STEP = 0.25;
export const FIRST_TOGGLE_ANIMATION_DELAY = 0.5 + TOGGLES_ANIMATION_STEP;
export const SECOND_TOGGLE_ANIMATION_DELAY = FIRST_TOGGLE_ANIMATION_DELAY + TOGGLES_ANIMATION_STEP;
export const LAST_TOGGLE_ANIMATION_DELAY = SECOND_TOGGLE_ANIMATION_DELAY + TOGGLES_ANIMATION_STEP;

const styledSvgAnimation = (opacityDelay?: string) =>
  animationTransitionExt({ property: 'fill' }, { property: 'stroke' }, { property: 'color' }, { property: 'opacity', delay: opacityDelay });

export const StyledIconV2 = styled(IconV2)`
  outline: none;
  cursor: pointer;
  transition: ${styledSvgAnimation()};
  &.animated {
    opacity: 0;
    &.mounted-0,
    &.mounted-1,
    &.mounted-2 {
      opacity: 1;
    }
    &.mounted-0 {
      transition: ${styledSvgAnimation(`${FIRST_TOGGLE_ANIMATION_DELAY}s`)};
    }
    &.mounted-1 {
      transition: ${styledSvgAnimation(`${SECOND_TOGGLE_ANIMATION_DELAY}s`)};
    }
    &.mounted-2 {
      transition: ${styledSvgAnimation(`${LAST_TOGGLE_ANIMATION_DELAY}s`)};
    }
  }
`;

export const IconWrapper: React.FC<PreviewToggleIconProps & { a11yLabel: string }> = ({ a11yLabel, className, color = 'mono8', active, children, previewOption }) => {
  const { t } = useTranslation('websiteDesigner');
  return (
    <StyledIconV2 color={active ? 'mono14' : color} className={className} aria-label={`${t('preview')()} ${a11yLabel}`}>
      {children}
    </StyledIconV2>
  );
};
