import React from 'react';
import { IconDesktop } from './IconDesktop';
import { IconPhone } from './IconPhone';
import { IconStationery } from './IconStationery';
import { PreviewOption } from '../../../../../WebsiteDesigner.types';

export interface PreviewToggleIconProps {
  active: boolean;
  className?: string;
  color?: string;
  previewOption: PreviewOption;
}

export const PreviewToggleIcons: { [previewOption in PreviewOption]: React.FC<PreviewToggleIconProps> } = {
  [PreviewOption.DESKTOP]: IconDesktop,
  [PreviewOption.MOBILE]: IconPhone,
  [PreviewOption.STATIONERY]: IconStationery
};
