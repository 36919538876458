import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@shared/core';
import { TextV2, TooltipV2 } from '@withjoy/joykit';
import { PreviewToggleWrapper, PreviewToggleItem } from './PreviewToggles.styles';
import { PreviewToggleIcons } from './components/PreviewToggleIcons';
import { PreviewOption } from '../../../../WebsiteDesigner.types';

export type PreviewTogglesProps = {
  activePreviewOption: PreviewOption;
  className?: string;
  isParentMounted: boolean;
  disableStationeryToggle?: boolean;
  onToggleSelect: (previewOption: PreviewOption) => void;
  toggleColor?: string;
};

export const PreviewToggles: React.FC<PreviewTogglesProps> = ({ activePreviewOption, disableStationeryToggle, isParentMounted, className, onToggleSelect, toggleColor }) => {
  const { t } = useTranslation('websiteDesigner');
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    if (isParentMounted) {
      setTimeout(() => {
        setShouldAnimate(true);
      }, 250);
    }
  }, [isParentMounted]);

  const mapSvgToToggle = useCallback(
    (previewOption: PreviewOption, index: number) => {
      const PreviewToggleIcon = PreviewToggleIcons[previewOption];
      const toggleClass = [shouldAnimate ? `animated mounted-${index}` : 'animated', previewOption === activePreviewOption && 'active'].filter(x => x).join(' ');
      const PreviewOptionText = (
        <TextV2 textAlign="center" typographyVariant="label2" color="mono4">
          {t(`titlePreview.${previewOption}`)()}
        </TextV2>
      );
      return (
        <TooltipV2 key={previewOption} content={PreviewOptionText} onMouseEnterDelay={200}>
          <PreviewToggleItem onClick={() => onToggleSelect(previewOption)}>
            <PreviewToggleIcon className={toggleClass} color={toggleColor} active={previewOption === activePreviewOption} previewOption={previewOption} />
          </PreviewToggleItem>
        </TooltipV2>
      );
    },
    [shouldAnimate, activePreviewOption, toggleColor, onToggleSelect, t]
  );

  const toggles = useMemo(() => ['desktop', 'mobile', !disableStationeryToggle && 'stationery'].filter(x => x) as ReadonlyArray<PreviewOption>, [disableStationeryToggle]);

  return (
    <PreviewToggleWrapper className={className} previewOption={activePreviewOption}>
      {toggles.map(mapSvgToToggle)}
    </PreviewToggleWrapper>
  );
};
