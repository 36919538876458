import React from 'react';
import { LinkV1, TextV1 } from '@withjoy/joykit';
import { Theme as ThemeIcon } from '@withjoy/joykit/icons';
import styles from './ThemeNotSupported.styles';

export const ThemeNotSupported: React.FC = () => {
  return (
    <TextV1 as="div" __css={styles.textStyles}>
      <ThemeIcon size={'md'} marginRight={1} />
      <span>Sorry but your theme is no longer available to preview. </span>
      <LinkV1 as="a" href="https://help.withjoy.com/knowledge-base/themes-in-preview" target="__blank" typographyVariant={'body1'}>
        Learn Why
      </LinkV1>
    </TextV1>
  );
};
