import React, { useRef } from 'react';
import { useImmer } from 'use-immer';
import { animated, useTransition } from 'react-spring';

import { ButtonV2, Flex, SpacingStack, TextV2 } from '@withjoy/joykit';
import { CardAlignment, Card } from './components/Card';
// import { Card } from '@withjoy/joykit/components/Card';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { animationTransition, animationTransitionExt } from '@shared/utils/animationTransition';
import { InteractionType, SuggestionCardProps, SuggestionCardState } from './SuggestionCard.types';
import { StyledCard, closingPollButtonStyleProps, contentWidthToWidth, getCardActionColorStyles, StyledRoot } from './SuggestionCard.styles';
import { isWhitespaceKeyClick } from '@withjoy/joykit/utils';

const ClosingPoll: React.FC<{ message: React.ReactNode; onUse: () => void; onSnooze: () => void; onDecline: () => void }> = ({ message, onUse, onSnooze, onDecline }) => {
  return (
    <Flex flexDirection="column" alignItems="center" marginX="auto">
      <TextV2 textAlign="center" typographyVariant="hed2" fontWeight={600}>
        {message}
      </TextV2>
      <SpacingStack stack="horizontal" spacing={5} marginTop={6}>
        <ButtonV2 {...closingPollButtonStyleProps} onClick={onUse}>
          Yep!
        </ButtonV2>
        <ButtonV2 {...closingPollButtonStyleProps} onClick={onSnooze}>
          Not yet
        </ButtonV2>
        <ButtonV2 {...closingPollButtonStyleProps} onClick={onDecline}>
          Not interested
        </ButtonV2>
      </SpacingStack>
    </Flex>
  );
};

const SuggestionCard: React.FC<SuggestionCardProps> = props => {
  const {
    content,
    action,
    alignment = 'left',
    backgroundImage,
    backgroundSize = 'contain',
    closingPollMessage,
    colorScheme,
    contentWidth = 'sm',
    id,
    identifier,
    onActionClick,
    enableClosingPoll,
    onInteract,
    onRemoved,
    stateJSON,
    title,
    ...restProps
  } = props as SuggestionCardProps;

  const [{ contentPhase }, setState] = useImmer<SuggestionCardState>(() => ({
    contentPhase: 'initial'
  }));

  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const isPhaseInitial = contentPhase === 'initial';
  const isPhaseClosingPoll = contentPhase === 'closingPoll';
  const isPhaseFarewell = contentPhase === 'farewell';

  const transitions = useTransition(!isPhaseFarewell, null, {
    from: { height: 211, opacity: 1, marginBottom: 40 },
    enter: { height: 211, opacity: 1, marginBottom: 40 },
    leave: { height: 0, opacity: 0, marginBottom: 0 },
    trail: enableClosingPoll ? 500 : 230,
    // ref: cardTransitionRef,
    onDestroyed: isDestroyed => {
      if (isDestroyed) {
        onRemoved?.(identifier);
      }
    }
  });

  ////////////////////////////
  // Event handlers

  const handleOnActionClick = useEventCallback(() => {
    onActionClick?.();
    onInteract?.({ id, identifier, interaction: 'use', source: 'cardAction', stateJSON });
  });

  const handleOnCardClick = useEventCallback<React.MouseEventHandler>(e => {
    // Considering clicking the card container (except the close button) the same as clicking the card action
    if (!closeButtonRef.current?.contains(e.target as Node)) {
      onInteract?.({ id, identifier, interaction: 'use', source: 'cardAction', stateJSON });
    }
  });

  const handleOnCardKeydown = useEventCallback<React.KeyboardEventHandler>(e => {
    // Considering pressing "enter" + "space" + "tab" keys on the container the same as clicking the card action
    if (isWhitespaceKeyClick(e.key)) {
      onInteract?.({ id, identifier, interaction: 'use', source: 'cardAction', stateJSON });
    }
  });

  const handleOnCloseButtonClick = useEventCallback(() => {
    setState(draft => {
      draft.contentPhase = enableClosingPoll ? 'closingPoll' : 'farewell';
    });

    onInteract?.({ id, identifier, interaction: 'decline', source: enableClosingPoll ? 'cardCloseButtonBeforePoll' : 'cardCloseButton', stateJSON });
  });

  const handleOnClosingPollButtonClick = useEventCallback((interaction: InteractionType) => {
    return () => {
      setState(draft => {
        draft.contentPhase = 'farewell';
      });

      onInteract?.({ id, identifier, interaction, source: 'closingPoll', stateJSON });
    };
  });

  ////////////////////////////
  // Render

  const renderContent = () => {
    if (isPhaseInitial) {
      return (
        <>
          <Card.CloseButton className="suggestion-card__close" ref={closeButtonRef} onClick={handleOnCloseButtonClick} />
          {backgroundImage && <Card.BgImage backgroundSize={backgroundSize} imageUrl={backgroundImage} />}
          <Card.Content padding={0} margin={7} fontFamily="'Inter UI'" width={contentWidthToWidth[contentWidth]}>
            {title && (
              <Card.Title typographyVariant={'hed4'} color={'mono14'} fontWeight={700}>
                {title}
              </Card.Title>
            )}
            {content && (
              <Card.Body typographyVariant={'body1'} color={'mono12'} fontSize={15}>
                {content}
              </Card.Body>
            )}
            {action && (
              <Card.Actions typographyVariant={'button1'} transition={animationTransition('color')} {...getCardActionColorStyles(colorScheme)} onClick={handleOnActionClick}>
                {action}
              </Card.Actions>
            )}
          </Card.Content>
        </>
      );
    } else if (isPhaseClosingPoll) {
      return (
        <ClosingPoll
          message={closingPollMessage}
          onUse={handleOnClosingPollButtonClick('use')}
          onSnooze={handleOnClosingPollButtonClick('snooze')}
          onDecline={handleOnClosingPollButtonClick('decline')}
        />
      );
    } else if (isPhaseFarewell && enableClosingPoll) {
      return (
        <TextV2 fontSize={17} fontWeight="bold">
          Awesome, thanks!
        </TextV2>
      );
    }

    return <div style={{ height: 211, width: '100%' }} />;
  };

  const getCardAsButtonProps = (): React.HTMLAttributes<HTMLDivElement> => {
    const containerProps: React.HTMLAttributes<HTMLDivElement> = {};
    if (isPhaseInitial) {
      containerProps.role = 'button';
      containerProps.tabIndex = 0;
      containerProps.onClick = handleOnCardClick;
      containerProps.onKeyDown = handleOnCardKeydown;
    }

    return containerProps;
  };

  const resolvedAlignment: CardAlignment = isPhaseFarewell ? 'center' : alignment;

  return (
    <>
      {transitions.map(
        ({ item, key, props: { height, marginBottom, ...transitionProps } }) =>
          item && (
            <StyledRoot key={key} as={animated.div} style={{ marginBottom: marginBottom?.interpolate(x => `${x}px`) }} $isInteractive={isPhaseInitial} {...getCardAsButtonProps()}>
              <StyledCard
                key={key}
                $contentPhase={contentPhase}
                alignment={resolvedAlignment}
                alignItems="center"
                cursor={isPhaseInitial ? 'pointer' : undefined}
                disableElevation={!isPhaseInitial}
                display="flex"
                minHeight={isPhaseFarewell ? undefined : '211px'}
                style={{
                  height: isPhaseFarewell ? height?.interpolate(x => `${x}px`) : undefined,
                  ...transitionProps
                }}
                transition={animationTransitionExt({ property: 'background-color' })}
                {...restProps}
              >
                {renderContent()}
              </StyledCard>
            </StyledRoot>
          )
      )}
    </>
  );
};

export { SuggestionCard };
