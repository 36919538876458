import { Box, Flex, FlexProps, TextV2 } from '@withjoy/joykit';
import React, { useMemo } from 'react';
import { useImmer } from 'use-immer';

import { SuggestionCard, OnCardInteract, SuggestionCardProps, ActiveSuggestionIdentifiers } from '../SuggestionCard';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { EventSuggestionFragment } from '@graphql/generated';
import { getEventSuggestionIdentifiersToShow } from './AdminFeed.utils';
import { useAdminFeedI18n } from './AdminFeed.i18n';

import { StyledZeroStateIcon, StyledViewPastText, StyledPullCard } from './AdminFeed.styles';
import WritersblockCard from './assets/chatgpt-card.jpg';
import AddPhotosSVg from './assets/photos1.svg';

import { ReactComponent as PullUpSVg } from './assets/arrow-pull-up.svg';

type CardStaticProps = Pick<SuggestionCardProps, 'backgroundImage' | 'alignment' | 'backgroundSize'>;
const CARDS: ReadonlyRecord<ActiveSuggestionIdentifiers, CardStaticProps> = {
  'reg-get-started': {
    alignment: 'left',
    backgroundImage: 'https://withjoy.com/assets/public/dashboard/cards/reg-get-started.jpg'
  },
  'reg-shop': {
    backgroundImage: 'https://withjoy.com/assets/public/dashboard/cards/reg-shop.jpg'
  },
  'reg-connect-registries': {
    backgroundImage: 'https://withjoy.com/assets/public/dashboard/cards/reg-connect-registries.jpg'
  },
  'reg-browse-cash-funds': {
    backgroundImage: 'https://withjoy.com/assets/public/dashboard/cards/reg-browse-cash-funds.jpg'
  },
  'reg-discount': {
    backgroundImage: 'https://withjoy.com/assets/public/dashboard/cards/reg-discount.jpg'
  },
  'onboarding-designs': {
    backgroundImage: 'https://withjoy.com/assets/public/dashboard/cards/onboarding-designs.jpg'
  },
  'new-update-designs-2020-01-14': {
    backgroundImage: 'https://withjoy.com/assets/public/dashboard/cards/new-update-designs-2020-01-14.jpg'
  },
  'onboarding-add-page-photos': {
    alignment: 'right',
    backgroundImage: AddPhotosSVg
  },
  writersblock: {
    backgroundSize: 'cover',
    backgroundImage: WritersblockCard
  }
};

const CARD_ENTRIES = Object.entries(CARDS) as [identifier: ActiveSuggestionIdentifiers, props: CardStaticProps][];

export interface AdminFeedProps
  extends Readonly<{
    className?: string;
    suggestions: ReadonlyArray<EventSuggestionFragment>;
    onInteract: OnCardInteract;
    onViewAllClick?: () => void;
  }> {}

const AdminFeed = React.forwardRef<HTMLDivElement, AdminFeedProps & FlexProps>(({ children, onInteract, onViewAllClick, suggestions, ...restProps }, ref) => {
  const [{ activeIdentifierSet }, setState] = useImmer<{ showAll: boolean; activeIdentifierSet: Set<ActiveSuggestionIdentifiers> }>(() => ({
    showAll: false,
    activeIdentifierSet: getEventSuggestionIdentifiersToShow(suggestions)
  }));

  const { eventSuggestions, adminFeed } = useAdminFeedI18n();

  const handleOnInteract = useEventCallback<OnCardInteract>(args => {
    onInteract(args);
  });

  const handleOnRemoved = useEventCallback((identifier: ActiveSuggestionIdentifiers) => {
    setState(draft => {
      draft.activeIdentifierSet.delete(identifier);
      if (draft.showAll) {
        draft.showAll = draft.activeIdentifierSet.size > 0;
      }
    });
  });

  const handleOnViewAllClick = useEventCallback(() => {
    onViewAllClick?.();
    setState(draft => {
      draft.showAll = true;
      draft.activeIdentifierSet = getEventSuggestionIdentifiersToShow(suggestions, true);
    });
  });

  const suggestionsByIdentifier = useMemo(() => {
    return suggestions.reduce((acc, cur) => {
      acc[cur.identifier as ActiveSuggestionIdentifiers] = cur;
      return acc;
    }, {} as Record<ActiveSuggestionIdentifiers, EventSuggestionFragment>);
  }, [suggestions]);

  const getCardsToShow = () => {
    const cardProps: SuggestionCardProps[] = [];
    CARD_ENTRIES.forEach(([identifier, componentProps]) => {
      if (activeIdentifierSet.has(identifier)) {
        const { id, stateJSON } = suggestionsByIdentifier[identifier as ActiveSuggestionIdentifiers];
        const { cta1, dek, hed } = eventSuggestions[identifier] || {};
        cardProps.push({ id, identifier, action: cta1, content: dek, onInteract: handleOnInteract, onRemoved: handleOnRemoved, stateJSON, title: hed, ...componentProps });
      }
    });
    return cardProps;
  };

  const cardsToShow = getCardsToShow();

  return (
    <Flex ref={ref} flexDirection="column" alignItems="center" marginX="auto" {...restProps}>
      {cardsToShow.map(({ content, ...cardProps }) => (
        <SuggestionCard key={cardProps.identifier} {...cardProps} />
      ))}
      {cardsToShow.length === 0 && (
        <>
          <Flex flexDirection="column" alignItems="center" textAlign="center" marginTop={108}>
            <StyledZeroStateIcon />
            <TextV2 color="black" fontFamily="'Inter UI'" fontSize={17} lineHeight="24px" marginTop={5} whiteSpace="pre-line">
              {adminFeed.zeroStateMessage}
            </TextV2>
          </Flex>
          <Box position="absolute" bottom={0} onClick={handleOnViewAllClick} cursor="pointer" textAlign="center">
            <StyledViewPastText color="black" typographyVariant="label3" fontWeight="bold" fontFamily="'Inter UI'" marginBottom={7}>
              {adminFeed.zeroStateViewPast}
            </StyledViewPastText>
            <StyledPullCard height={'48px'} width="380px" borderTopLeftRadius="8px" borderTopRightRadius="8px" background="rgba(255, 255, 255, 0.4)">
              <PullUpSVg />
            </StyledPullCard>
          </Box>
        </>
      )}
    </Flex>
  );
});

AdminFeed.displayName = 'AdminFeed';

export { AdminFeed };
