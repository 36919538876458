import { useMemo } from 'react';
import { useTranslation } from '@shared/core';

export const useAdminFeedI18n = () => {
  const { t2 } = useTranslation('suggestions');

  const translations = useMemo(() => {
    return {
      eventSuggestions: t2('eventSuggestions'),
      adminFeed: t2('adminFeed')
    };
  }, [t2]);

  return translations;
};
