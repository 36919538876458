import { AdminFeed, StyledViewPastText, StyledZeroStateIcon } from '@apps/suggestions/components/AdminFeed';
import { Box, BoxProps, ButtonV2, Flex, pseudoSelectors, styled } from '@withjoy/joykit';
import { ButtonOverrides } from '@withjoy/joykit/components/ButtonV2/Button.types';
import { animationTransition } from '@shared/utils/animationTransition';
import { PreviewToggles } from '../WebsiteDesigner/components/PreviewToggles';
import { WebsitePreview } from '../WebsiteDesigner/components/WebsitePreview';

export const previewContainerPropsByTabIndex: ReadonlyRecord<number, BoxProps> = {
  0: {
    minHeight: '100%'
  },
  1: {
    height: '100%'
  }
};

export const tabButtonOverrides: ButtonOverrides = {
  Root: {
    props: {
      borderRadius: '48px',
      _hover: {
        // eslint-disable-next-line
        color: 'rgba(0, 0, 0, 0.6)' as any
      },
      _active: {
        // eslint-disable-next-line
        color: 'rgba(0, 0, 0, 1)' as any
      }
    }
  }
};

export const StyledRoot = styled(Flex)<{ $bgColor?: string }>`
  ${props => {
    return {
      backgroundColor: props.$bgColor
    };
  }}
  transition: ${animationTransition('background-color')};
`;

export const StyledContainer = styled(Box)`
  padding: 0;
  ${props => ({ backgroundColor: props.theme.colors.mono1 })}
`;

export const StyledAdminFeed = styled(AdminFeed)<{ $fgColor: string; $bgColorEmphasized: string }>`
  ${({ $fgColor, $bgColorEmphasized, theme }) => {
    return {
      color: $fgColor,
      [StyledZeroStateIcon]: {
        color: $bgColorEmphasized
      },
      [StyledViewPastText]: {
        color: theme.colors.buttonText,
        opacity: 0.8
      }
    };
  }}
`;

export const StyledWebsitePreviewContainer = styled(Box)<{ isCompactMode: boolean; isScreenMobile: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  flex-direction: ${({ isCompactMode }) => (isCompactMode ? 'column-reverse' : 'column')};
  height: ${({ isCompactMode, isScreenMobile }) => (isCompactMode && isScreenMobile ? '100%' : 'auto')};
  padding-left: 1.5rem;
  padding-right: 1.125rem;

  ${({ isScreenMobile, isCompactMode }) =>
    isScreenMobile &&
    isCompactMode &&
    `
    @media screen and (max-width: 768px) and (orientation: portrait) {
      #website-preview {
        width: 100% !important;
        height: 100vh !important;
        transform: none !important;
        min-width: unset !important;
        min-height: unset !important;
      }

      #StyledPreviewTogglesBox {
        display: none;
      }
    }
  `};
`;

export const StyledWebsitePreview = styled(WebsitePreview)`
  max-height: calc(100% - 40px);
`;

export const StyledContentWrapper = styled(Box)<{ $isScrollBarVisible: boolean }>`
  position: relative;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  ::-webkit-scrollbar {
    width: 8px;
    /* To avoid Safari;s momentum based scrolling, set this to "none"   */
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar {
    /* background-color: rgba(255, 255, 255, 0); */
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border: 24px solid rgba(255, 255, 255, 0);
    /* border-radius: 8px; */
    background-clip: padding-box;
    transition: ${animationTransition('background-color')};
  }

  ${({ $isScrollBarVisible }) => {
    return {
      '::-webkit-scrollbar-track': {
        backgroundColor: `rgba(0, 0, 0, ${$isScrollBarVisible ? 0.1 : 0} )`
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: `rgba(255, 255, 255, ${$isScrollBarVisible ? 1 : 0} )`
      }
    };
  }}
`;

export const StyledTabButtonsContainer = styled(Flex)`
  ${props => ({
    backgroundColor: props.theme.colors.mono2,
    width: 'fit-content',
    margin: '16px auto',
    borderRadius: '2rem',
    padding: '10px'
  })}
`;

export const StyledTabButton = styled(ButtonV2)<{ $isActive: boolean }>`
  ${({ theme, $isActive }) => {
    return {
      color: 'inherit',
      cursor: $isActive ? 'default' : 'pointer',
      backgroundColor: $isActive ? 'white' : 'unset',
      outline: 0,
      transition: animationTransition('box-shadow', 'color', 'background-color', 'background', 'opacity'),
      [pseudoSelectors._visited]: {
        color: theme.colors.linkVisited
      },
      [pseudoSelectors._active]: {
        color: theme.colors.linkActive
      },
      [pseudoSelectors._hover]: {
        color: theme.colors.linkHover
      }
    };
  }}
`;

export const StyledPseudoButton = styled(Box)`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  ${props => {
    return {
      [pseudoSelectors._visited]: {
        color: props.theme.colors.linkVisited
      },
      [pseudoSelectors._active]: {
        color: props.theme.colors.linkActive
      },
      [pseudoSelectors._hover]: {
        color: props.theme.colors.linkHover
      }
    };
  }}
`;

export const StyledPreviewTogglesBox = styled(Box)<{ isCompactMode: boolean }>`
  ${({ isCompactMode }) =>
    isCompactMode
      ? {}
      : {
          bottom: '-4rem',
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
`;

export const StyledPreviewToggles = styled(PreviewToggles)<{ $activeColor: string; $color: string }>`
  svg {
    ${props => {
      return {
        color: props.$color,
        '&.active': {
          color: props.$activeColor
        }
      };
    }}
  }
`;
