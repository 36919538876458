import { ButtonV2Props, pseudoSelectors, styled, StyleSystemProps } from '@withjoy/joykit';
import { PrimitiveColorTokens } from '@withjoy/joykit/theme';
import { Card, CardAlignment } from './components/Card';
// import { Card } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';
import { CardColorScheme, ContentPhase, ContentWidth } from './SuggestionCard.types';

const contentPhaseToBgColor: ReadonlyRecord<ContentPhase, string> = {
  initial: '#fff',
  closingPoll: 'rgba(0, 0, 0, 0.05)',
  farewell: 'transparent'
};

const alignmentToJustify: ReadonlyRecord<CardAlignment, React.CSSProperties['justifyContent']> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

export const contentWidthToWidth: ReadonlyRecord<ContentWidth, string> = {
  sm: '48%',
  md: '54.68%',
  fill: '100%'
};

export const StyledRoot = styled.div<{ $isInteractive: boolean }>`
  transition: ${animationTransition('transform')};
  max-width: 100%;
  outline: 0;
  ${({ $isInteractive }) => {
    if ($isInteractive) {
      return {
        [pseudoSelectors._active]: {
          transform: 'scale(0.98)'
        }
      };
    }
    return {};
  }}
`;

export const StyledCard = styled(Card)<{ $contentPhase: ContentPhase; alignment: CardAlignment }>`
  ${({ $contentPhase, alignment }) => {
    return {
      backgroundColor: contentPhaseToBgColor[$contentPhase],
      justifyContent: alignmentToJustify[alignment]
    };
  }}/* &:active {
    transform: scale(1.04) !important;
  } */
`;

export const closingPollButtonStyleProps: Readonly<ButtonV2Props> = {
  size: 'sm',
  backgroundColor: 'white',
  typographyVariant: 'body2',
  overrides: {
    Root: {
      props: {
        fontSize: 13,
        backgroundColor: 'white',
        color: 'linkText',
        _hover: {
          opacity: 0.8
        },
        _active: {
          opacity: 0.6
        }
      }
    }
  }
};

type PrimitiveColorTokenKey = keyof PrimitiveColorTokens;

export const getCardActionColorStyles = (colorScheme?: CardColorScheme): StyleSystemProps => {
  if (!colorScheme) {
    return { color: '#000' };
  }

  return {
    color: `${colorScheme}5` as PrimitiveColorTokenKey,
    _hover: {
      color: `${colorScheme}4` as PrimitiveColorTokenKey
    },
    _active: {
      color: `${colorScheme}6` as PrimitiveColorTokenKey
    }
  };
};
