import React from 'react';
import { IconWrapper } from './IconWrapper';
import { PreviewToggleIconProps } from './PreviewToggleIcons';
import { Mobile } from '@withjoy/joykit/icons';

export const IconPhone: React.FC<PreviewToggleIconProps> = props => {
  return (
    <IconWrapper {...props} a11yLabel="mobile">
      <Mobile />
    </IconWrapper>
  );
};
