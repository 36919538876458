import React, { useEffect, useMemo, useRef, useState } from 'react';

import { WebsitePreviewWrapper, Container, Iframe, PreviewBottomActions } from './WebsitePreview.styles';
import { DesignLayoutType, GuestSiteTargetEnum } from '@graphql/generated';
import { SketchedJoyLogo } from '@apps/admin/common/SketchedJoyLogo';
import { previewMessageBusSubject, PreviewIframeMessage, getTargetOrigin, createPreviewMessageEventHandler, LegacyPreviewTypes } from '@shared/utils/previewMessageBus';
import { getNodeEnv } from '@shared/utils/getEnvironmentVariables';
import { lazy } from '@loadable/component';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { calculateBottomActionStyle, constructPreviewUrl, usePreviewIframe } from './';
import { isInIframe } from '@shared/utils/isInIframe';
import { animated } from 'react-spring';
import ThemeNotSupported from './components/ThemeNotSupported';
import { useIsomorphicLayoutEffect } from '@shared/utils/hooks/useIsomorphicLayoutEffect';
import { useSuspended } from '@shared/core/react/Suspense';
import { useFeatureValue } from '@shared/core/featureFlags';
import { PreviewDevice } from '@apps/admin/routes/WebsiteDesignerV2/WebsiteDesigner.types';

const isIframed = isInIframe();
const { isProduction } = getNodeEnv();

const DomainSearch = lazy(
  () =>
    /* webpackChunkName: "content/domain" */
    import('@apps/domain')
);

interface WebsitePreviewProps {
  className?: string;
  currentLayoutType: DesignLayoutType | undefined;
  disableDomainSearch?: boolean;
  eventHandle: string;
  hasInitializedOnce: boolean;
  hideWhenNotShown: boolean;
  isMobile: boolean;
  isShown?: boolean;
  isThemeSupportedByCurrentLayout?: boolean;
  leftOffset?: number;
  onInitialized?: () => void;
  previewDevice: PreviewDevice;
  renderContext: 'websiteDesigner' | 'adminGuestSitePreview';
  contentBelow?: React.ReactNode;
  themeId?: string;
  themeNotSupported?: boolean;
  disableScroll?: boolean;
  reducedDashboardHeight?: boolean;
}

export type WebsitePreviewRenderContext = 'websiteDesigner' | 'adminGuestSitePreview';

type ResizeAnimationOptions = {
  disableInitialReveal?: boolean;
};

const hasPreviewInitializedFromSource = (event: MessageEvent) =>
  event.data.source === LegacyPreviewTypes.SOURCE_JOY_PREVIEW && event.data.action === LegacyPreviewTypes.ACTION_ON_INITIALIZED;

export const WebsitePreview: React.FC<WebsitePreviewProps> = React.memo(props => {
  const {
    contentBelow,
    disableDomainSearch,
    isMobile,
    isShown = true,
    previewDevice,
    className,
    eventHandle,
    onInitialized,
    currentLayoutType = DesignLayoutType.brannan,
    hasInitializedOnce,
    hideWhenNotShown,
    leftOffset = 0,
    renderContext,
    themeNotSupported,
    disableScroll = false,
    reducedDashboardHeight
  } = props;
  const [{ targetOrigin, hasWaitedInitialDelay, currentPreviewDevice, hasIframeInitialized, previewUrl }, setState] = useState<{
    targetOrigin: string;
    hasWaitedInitialDelay: boolean;
    currentPreviewDevice: PreviewDevice;
    hasIframeInitialized: boolean;

    /**
     * To support charm/bliss as preview, `previewUrl` is determined asynchronously.
     * Must factor in current layout + theme.
     */
    previewUrl: string;
    previewTarget: GuestSiteTargetEnum;
  }>(() => {
    const origin = isProduction ? '' : getTargetOrigin();
    return {
      targetOrigin: origin,
      hasWaitedInitialDelay: false,
      currentPreviewDevice: previewDevice,
      hasIframeInitialized: false,
      previewUrl: '',
      previewTarget: GuestSiteTargetEnum.bliss
    };
  });

  const shouldRevealContainer = isShown || (!isShown && !hideWhenNotShown);

  // Set refs
  const containerRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Below, resize() is memoized and a dependency for an effect that tracks preview visibility.
  // We will store the previous value of "isShown" so that resize() is not needlessly triggered.
  const prevTrackedProps = useRef<{ isShown: boolean; previewDevice: PreviewDevice }>({
    isShown,
    previewDevice
  });

  const changePreviewDevice = useEventCallback((previewDevice: PreviewDevice) => {
    setState(prev => ({ ...prev, currentPreviewDevice: previewDevice }));
  });

  const { resize, springProps, toggleIframeDisplay } = usePreviewIframe({
    changePreviewDevice,
    containerRef,
    currentLayoutType,
    iframeRef,
    isMobile,
    leftOffset,
    previewDevice
  });

  //====================================================================================
  // Callbacks
  //====================================================================================

  const prepareIframeForUse = useEventCallback(() => {
    setState(prev => ({ ...prev, hasIframeInitialized: true }));
  });

  const sendMessage = useEventCallback((msg: PreviewIframeMessage) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(msg, targetOrigin || `https://${document.domain}`);
    }
  });

  const receiveMessage = useMemo(() => {
    if (isIframed) {
      // This component is used in the new admin dashboard preview panel.
      // Admin dashboard preview iframe -> WebsitePreview component iframe -> GuestSite
      return createPreviewMessageEventHandler((msg, ev) => {
        // Pass the raw message data to the iframe
        sendMessage(ev.data);
        prepareIframeForUse();
      });
    }

    return (ev: MessageEvent) => {
      if (hasPreviewInitializedFromSource(ev)) {
        prepareIframeForUse();
        onInitialized?.();
      }
    };
  }, [prepareIframeForUse, onInitialized, sendMessage]);

  // Prefer `useEventCallback` over `useCallback` for stability across renders + dependency changes

  // FeatureFlag => wait for StyleApplicator https://github.com/joylifeinc/joy-web/pull/3214
  const enableStyledApplicatorLoading = useFeatureValue('enableLoadingStyleApplicator');

  //====================================================================================
  // Lifecycle
  //====================================================================================

  useEffect(() => {
    const guestSiteTarget = GuestSiteTargetEnum.charm;
    const url = constructPreviewUrl(targetOrigin, eventHandle, renderContext, guestSiteTarget, !!enableStyledApplicatorLoading);
    setState(prev => {
      return {
        ...prev,
        previewUrl: url,
        previewTarget: guestSiteTarget,
        hasIframeInitialized: prev.previewUrl === url
      };
    });
  }, [eventHandle, renderContext, targetOrigin, enableStyledApplicatorLoading]);

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  useEffect(() => {
    if (!isIframed) {
      // This block is intended for WebsiteDesigner usage
      const sub = previewMessageBusSubject.subscribe({
        next: action => {
          switch (action.type) {
            case 'resize':
              resize();
              break;
            case 'sendMessage':
              sendMessage(action.payload.message);
              break;
          }
        }
      });

      return () => {
        sub?.unsubscribe();
      };
    }
    return () => {};
  }, [resize, sendMessage]);
  useEffect(() => {
    // Track relevant triggers + states
    let isTransitioningFromNonDevice = false;
    let isTransitioningPreviewDevice = false;
    let shouldDelayResize = false;

    // Preview goes from shown -> not shown when viewing stationary/css and then back to device preview
    if (prevTrackedProps.current.isShown !== isShown) {
      prevTrackedProps.current.isShown = isShown;
      isTransitioningFromNonDevice = true;
      shouldDelayResize = true;
    }

    // Switching from mobile -> desktop, vice versa
    if (isShown && prevTrackedProps.current.previewDevice !== previewDevice) {
      prevTrackedProps.current.previewDevice = previewDevice;
      isTransitioningPreviewDevice = true;
    }

    // 1. If the preview's visibility is being toggled, we'll want delay resizing to ensure proper scale + dimensions.
    // 2. If transitioning devices, animate imediately since the container will have begun transitioning
    // For example, stationary -> preview device
    if (isShown && (isTransitioningFromNonDevice || isTransitioningPreviewDevice)) {
      const resizeAnimationOptions: boolean | ResizeAnimationOptions = isTransitioningFromNonDevice
        ? // If transitioning from stationery -> device, disable initial reveal animation if preview device has not changed
          //    - Initial reveal animation is intended for transitioning devices
          { disableInitialReveal: !isTransitioningPreviewDevice }
        : isTransitioningPreviewDevice;
      const initiateResize = () => resize(resizeAnimationOptions);
      if (shouldDelayResize) {
        setTimeout(() => initiateResize(), 250);
      } else {
        initiateResize();
      }
    }
  }, [previewDevice, isShown, hasWaitedInitialDelay, resize]);

  useEffect(() => {
    if (hasInitializedOnce) {
      setState(prev => ({ ...prev, hasWaitedInitialDelay: true }));
    }
  }, [hasInitializedOnce]);

  useEffect(() => {
    if (hasIframeInitialized) {
      toggleIframeDisplay(true);
    }
  }, [hasIframeInitialized, toggleIframeDisplay]);

  const isSuspended = useSuspended();
  const shouldTriggerResize = !isSuspended && shouldRevealContainer;
  useIsomorphicLayoutEffect(() => {
    if (shouldTriggerResize) {
      setTimeout(() => {
        resize();
      }, 250);
    }
  }, [currentLayoutType, shouldTriggerResize, resize]);

  useEffect(() => {
    sendMessage({ action: LegacyPreviewTypes.ACTION_SET_PREVIEW_TYPE, source: 'joy', value: isMobile ? 'mobile' : 'desktop' });
  }, [isMobile, sendMessage]);

  //====================================================================================
  // Render
  //====================================================================================
  const { scale, height, width } = springProps;

  return (
    <Container show={shouldRevealContainer} leftOffset={leftOffset} className={className} reducedDashboardHeight={reducedDashboardHeight}>
      <WebsitePreviewWrapper
        id="website-preview" // adding id to website preview so Erica can select it for Chamelon
        ref={containerRef}
        previewDevice={currentPreviewDevice}
        style={{
          transform: scale?.interpolate(factor => `scale(${factor})`),
          willChange: isMobile ? undefined : 'transform',
          minWidth: width,
          minHeight: height,
          width,
          height
        }}
      >
        {!disableDomainSearch && !isMobile && currentPreviewDevice === 'desktop' && <DomainSearch eventHandle={eventHandle} context="website-designer" />}
        <SketchedJoyLogo containerHeight="100%" shouldAnimate={!hasIframeInitialized} />

        {hasWaitedInitialDelay && (
          <Iframe
            ref={iframeRef}
            // Setting key will guarantee a re-render when the url changes
            key={previewUrl}
            style={{ display: hasIframeInitialized || hasInitializedOnce ? undefined : 'none', pointerEvents: disableScroll ? 'none' : 'auto' }}
            sandbox={`allow-scripts allow-same-origin`}
            src={previewUrl}
            tabIndex={-1}
          />
        )}
      </WebsitePreviewWrapper>
      {themeNotSupported ? (
        <animated.div
          style={{
            position: 'absolute',
            transform: `translateY(-50%)`,
            top: calculateBottomActionStyle(previewDevice, springProps).bottom
          }}
        >
          <ThemeNotSupported />
        </animated.div>
      ) : null}
      {hasWaitedInitialDelay && contentBelow && <PreviewBottomActions style={calculateBottomActionStyle(previewDevice, springProps)}>{contentBelow}</PreviewBottomActions>}
    </Container>
  );
});
