import React, { useEffect, useRef } from 'react';
import { useIsomorphicLayoutEffect } from '@shared/utils/hooks/useIsomorphicLayoutEffect';

const useLatest = <T>(current: T) => {
  const storedValue = useRef<T>(current);
  useEffect(() => {
    storedValue.current = current;
  });
  return storedValue;
};

//

export type UseMutationObserverCallback = (record: MutationRecord, observer: MutationObserver) => void;

const createMutaionObserver = () => {
  const callbacks: Set<MutationCallback> = new Set();

  return {
    observer: new MutationObserver((mutations, observer) => {
      callbacks.forEach(callback => {
        callback(mutations, observer);
      });
    }),
    subscribe: (callback: MutationCallback) => callbacks.add(callback),
    unsubscribe: (callback: MutationCallback) => callbacks.delete(callback)
  };
};

export const useMutationObserver = <T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  options: MutationObserverInit,
  callback: UseMutationObserverCallback
): MutationObserver => {
  const storedCallback = useLatest(callback);

  const mutationObserverRef = useRef(createMutaionObserver());

  useIsomorphicLayoutEffect(() => {
    let didUnsubscribe = false;

    const callback = (records: MutationRecord[], observer: MutationObserver) => {
      if (didUnsubscribe) return;
      // const targetEl = target && 'current' in target ? target.current : target;

      for (let i = 0; i < records.length; i++) {
        const record = records[i];
        // if (record.target === targetEl) {
        storedCallback.current(record, observer);
        // }
      }
    };

    const mutationObserver = mutationObserverRef.current;

    mutationObserver.subscribe(callback);
    return () => {
      didUnsubscribe = true;
      mutationObserver.unsubscribe(callback);
    };
  }, [target, mutationObserverRef, storedCallback]);

  useIsomorphicLayoutEffect(() => {
    const targetEl = target && 'current' in target ? target.current : target;
    if (!targetEl) return;

    const observer = mutationObserverRef.current.observer;
    observer.observe(targetEl, options);
    return () => observer.disconnect();
  }, [target, mutationObserverRef.current.observer, options]);

  return mutationObserverRef.current.observer;
};
