import { LinkV2, styled } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { animationTransition } from '@shared/utils/animationTransition';
import { PreviewOption } from '../../../../WebsiteDesigner.types';
import { StyledIconV2 } from './components/IconWrapper';

export const PreviewToggleWrapper = styled.div<{ previewOption: PreviewOption }>`
  transition: ${animationTransition('max-width')};
  display: flex;
  position: relative;
  padding: ${props => props.theme.space[2]};
  gap: ${props => props.theme.space[2]};
  &:after {
    position: absolute;
    content: ' ';
    background: ${props => props.theme.colors.white};
    border-radius: ${pxToRem(28)};
    box-shadow: 0px 1px 3px rgb(51 51 51 / 10%);
    width: 52px;
    height: 52px;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: translateX(${({ previewOption }) => (previewOption === 'desktop' ? '0px' : previewOption === 'mobile' ? '58px' : '116px')});
    transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  }
`;

export const PreviewToggleItem = styled(LinkV2)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${pxToRem(52)};
  width: ${pxToRem(52)};
  z-index: 2;
  padding: ${props => props.theme.space[5]};
  :hover {
    ${StyledIconV2} {
      color: ${props => props.theme.colors.linkHover};
    }
  }
  :active {
    ${StyledIconV2} {
      color: ${props => props.theme.colors.linkActive};
    }
  }
`;
