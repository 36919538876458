import { useEffect, useState } from 'react';

const fetchThemeJSON = async (themeId: string) => {
  if (themeId === 'blank') {
    return true;
  }
  try {
    // NOTE: I'd love a better way to call this but didn't find anything useful in the config at the moment
    const res = await fetch(`https://withjoy.com/assets/public/styles/dev/${themeId}/style2x.json`);
    if (res.ok) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

/**
 * Makes a lookup to s3 for the styles.json for a particular theme of themeId
 *
 * returns boolean whether the theme can be rendered by styleApplicator.
 * will return undefined until a response comes back from the request
 * @param themeId the canonical theme id e.g. vintage_sunset
 */
export const useThemeUsesStyleApplicator = (themeId?: string) => {
  const [result, reportResult] = useState<boolean | undefined>();

  useEffect(() => {
    if (themeId) {
      fetchThemeJSON(themeId).then(reportResult);
    }
  }, [themeId]);

  return result;
};
