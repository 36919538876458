import React, { createContext, useContext } from 'react';
import type { CardContextValue } from './Card.types';

export const CardContext = createContext<CardContextValue | undefined>(undefined);

export const useCardContext = () => {
  const context = useContext(CardContext);
  if (!context) {
    throw new Error('useCardContext should be nested under a <CardContext />');
  }
  return context;
};

export const CardContextProvider: React.FC<{ value: CardContextValue }> = ({ children, value }) => {
  return <CardContext.Provider value={value}>{children}</CardContext.Provider>;
};
