import React from 'react';
import { styled, css } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';
import { animated } from 'react-spring';
import { PreviewDevice } from '@apps/admin/routes/WebsiteDesignerV2/WebsiteDesigner.types';

export const Iframe = styled(animated.iframe)`
  width: 1px;
  min-width: 100%;
  height: 1px;
  min-height: 100%;
  background-color: white;
  border: none;
  transform-origin: top left;
  opacity: 0;
  display: none;
  transition: ${animationTransition('opacity', 'border-radius')};

  &.visible {
    opacity: 1;
  }

  &.shown {
    display: block;
  }

  @media screen and (min-width: 769px) {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`;

type WrapperProps = { previewDevice: PreviewDevice; children: React.ReactNode; style: React.CSSProperties; id: string };

const Root = React.forwardRef<HTMLDivElement, WrapperProps>(({ previewDevice, ...restProps }, ref) => {
  return <animated.div {...restProps} ref={ref} />;
});

export const WebsitePreviewWrapper = styled(Root)<WrapperProps>`
  box-shadow: 0px 8px 40px rgba(51, 51, 51, 0.16);
  background-color: #fbfbfb;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  transition: ${animationTransition('border-radius', 'padding', 'box-shadow')};

  border-bottom-right-radius: ${props => props.theme.radii[2]};
  border-bottom-left-radius: ${props => props.theme.radii[2]};

  @media screen and (min-width: 769px) {
    transform: translate3d(0, 0, 0);
  }

  &::after {
    content: ' ';
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 24px;
    border-radius: 50%;
    transform: translateY(-50%);
    left: 12px;
    background-repeat: no-repeat;
    box-shadow: 24px 0 0 0 #ebebeb, 48px 0 0 0 #ebebeb;
    background: #ebebeb;
    opacity: 1;
    transition: ${animationTransition('opacity')};
  }

  ${props => {
    if (props.previewDevice === 'mobile') {
      // return {
      //   maskImage: `url(${IphoneSvg})`
      // };
      return css`
        padding: 26px 10px;
        border-radius: 22px;
        &::after {
          opacity: 0;
        }
        ${Iframe} {
          border-radius: 6px;
        }
      `;
    } else {
      return css`
        padding: 48px 0 0 0;
      `;
    }
  }}
`;

export const Container = styled.div<{ show: boolean; leftOffset: number; reducedDashboardHeight?: boolean }>`
  display: ${props => (props.show ? 'flex' : 'none')};
  position: relative;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: ${props => (props.reducedDashboardHeight ? '365px' : '60vh')};
  height: 719px;
  width: 900px;
`;

export const PreviewBottomActions = styled(animated.div)`
  display: flex;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.01em;
  position: absolute;
  width: 100%;
  svg {
    margin-right: 0.5rem;
  }
`;
