import type { StyleSystemProps } from '@withjoy/joykit';
import { CardAlignment, CardSize, CardSizeScale } from './Card.types';

const sizeToDimensions: ReadonlyRecord<CardSizeScale, { width: string }> = {
  md: {
    width: '384px'
  }
};

const bgImagePosition: ReadonlyRecord<CardAlignment, CardAlignment> = {
  left: 'right',
  center: 'center',
  right: 'left'
};

export const getCardStyles = (alignment: CardAlignment, size: CardSize): StyleSystemProps => {
  return {
    backgroundColor: 'white',
    borderRadius: 3,
    overflow: 'hidden',
    maxWidth: '100%',
    position: 'relative',
    textAlign: alignment,
    width: typeof size === 'number' ? size : size === 'md' ? sizeToDimensions[size as CardSizeScale].width : undefined
  };
};

////////////////

export const getCardBgImageStyles = (imageUrl: string, cardAlignment: CardAlignment): StyleSystemProps => {
  const assetAlignment = bgImagePosition[cardAlignment];
  return {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: assetAlignment,
    backgroundRepeat: 'no-repeat',
    backgroundSize: imageUrl.endsWith('.png') ? 'cover' : 'contain',
    position: 'absolute',
    transformOrigin: 'center'
  };
};

export const cardContentStyles: StyleSystemProps = {
  padding: 7,
  position: 'relative'
};

////////////////

export const cardTitleStyles: StyleSystemProps = {
  typographyVariant: 'hed2'
};

////////////////

export const cardBodyStyles: StyleSystemProps = {
  fontSize: 15,
  marginTop: 4,
  typographyVariant: 'body3'
};

////////////////

export const cardActionStyles: StyleSystemProps = {
  display: 'inline-flex',
  alignItems: 'center',
  marginTop: 5,
  fontSize: 13
};
